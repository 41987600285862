import './index.scss';
import {Link, NavLink} from 'react-router-dom';
import LogoP from '../../assets/images/logo-p.png';
import LogoSubtitle from '../../assets/images/logo_sub.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faUser, faBars, faSuitcase, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useState } from 'react';

const Sidebar = () => {
    const [showNav, setShowNav] = useState(false);

    return(
        <div className="nav-bar">
            <Link className="logo" to="/">
                <img src={LogoP} alt="logo"></img>
                <img className='sub-logo' src={LogoSubtitle} alt="pgpablodev"></img>         
            </Link>
            <nav className={showNav ? 'mobile-show' : ''}>
                <NavLink
                onClick={() => setShowNav(false)}
                exact="true" activeclassname="active" to="/">
                    <FontAwesomeIcon icon={faHome} color="#4d4d4e"></FontAwesomeIcon>
                </NavLink>
                <NavLink
                onClick={() => setShowNav(false)}
                exact="true" activeclassname="active" className="career-link" to="/career">
                    <FontAwesomeIcon icon={faArrowTrendUp} color="#4d4d4e"></FontAwesomeIcon>
                </NavLink>
                <NavLink
                onClick={() => setShowNav(false)}
                exact="true" activeclassname="active" className="portfolio-link" to="/portfolio">
                    <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e"></FontAwesomeIcon>
                </NavLink>
                {/*<NavLink
                onClick={() => setShowNav(false)}
                exact="true" activeclassname="active" className="about-link" to="/about">
                    <FontAwesomeIcon icon={faUser} color="#4d4d4e"></FontAwesomeIcon>
                </NavLink>*/}
                <NavLink
                onClick={() => setShowNav(false)}
                exact="true" activeclassname="active" className="contact-link" to="/contact">
                    <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"></FontAwesomeIcon>
                </NavLink>                
                <FontAwesomeIcon
                onClick={() => setShowNav(false)}
                icon={faBars} color={"#708eb4"} size="3x"  className="hamburger-icon"/>
            </nav>
            <ul>
                <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/pablo-povar-gimenez">
                        <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e"/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.github.com/pgpablodev">
                        <FontAwesomeIcon icon={faGithub} color="#4d4d4e"/>
                    </a>
                </li>
            </ul>
            <FontAwesomeIcon
             onClick={() => setShowNav(true)}
             icon={faBars} color={"#708eb4"} size="3x" className="hamburger-icon"/>
        </div>
    );
}

export default Sidebar;